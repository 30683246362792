<div class="app-confirmation-modal">
  <ng-container *ngIf="!isWarning">
    <div class="app-confirmation-modal-icon">
      <img [src]="defineIconImage()" />
    </div>
    <h1 class="app-confirmation-modal-title">{{ data.title }}</h1>
    <p class="app-confirmation-modal-subtext">{{ data.description }}</p>
  </ng-container>
  <ng-container *ngIf="isWarning">
    <div class="app-confirmation-modal-warning-section">
      <img class="app-confirmation-modal-warning-icon" [src]="defineIconImage()" />
      <h1 class="app-confirmation-modal-warning-title">{{ data.title }}</h1>
    </div>
    <p class="app-confirmation-modal-warning-subtext">{{ data.description }}</p>
    <p class="app-confirmation-modal-warning-subtext-detail">{{ data.detailDescription }}</p>
  </ng-container>
  <div class="app-confirmation-modal-buttons">
    <app-button
      (clickEvent)="onButtonNoClick()"
      [text]="data.buttonNoText"
    ></app-button>
    <app-button
      class="btn-accept"
      (clickEvent)="onButtonYesClick()"
      [text]="data.buttonYesText"
      [submitType]="true"
    ></app-button>
  </div>
</div>
