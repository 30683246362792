<div *ngIf="dataSource" class="app-table-container">
  <table mat-table [dataSource]="dataSource" matSort aria-hidden="true">
    <ng-container
      *ngFor="let tableRow of appTableRows"
      [matColumnDef]="tableRow.columnName"
    >
      <th
        class="app-table-row-header"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ tableRow.displayName }}
      </th>

      <td class="app-table-row-cell" mat-cell *matCellDef="let row">
        <ng-template
          [ngTemplateOutlet]="rowTemplate"
          [ngTemplateOutletContext]="{
            $value: row[tableRow.columnName],
            columnName: tableRow.columnName,
            row: row
          }"
        >
        </ng-template>
        <ng-container *ngIf="!rowTemplate">
          {{ row[tableRow.columnName] }}
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
</div>
