import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

export interface UploadEvidenceHeaders {
  headers: {
    'x-ms-blob-type'?: 'BlockBlob';
    'x-ms-meta-app_id': string;
    'x-ms-meta-user_id': string;
    'x-ms-meta-functional_check_id': string;
    'x-ms-meta-file_name': string;
  };
}
@Injectable({
  providedIn: 'root',
})
export class SecurityFunctionalChecksService extends CommonService {
  testStatus: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  paginatorSize: BehaviorSubject<number> = new BehaviorSubject<number>(8);

  public getFunctionalChecks(appGuid: string) {
    return this.httpClient.get<any>(
      environment.urls.apiBaseUrl + `apps/${appGuid}/functional_checks`,
      { withCredentials: true },
    );
  }

  public getEvidenceHistory(appGuid: string, functionalCheckHash: string) {
    return this.httpClient.get<any>(
      environment.urls.apiBaseUrl +
        `apps/${appGuid}/functional_checks/${functionalCheckHash}/evidences`,
      { withCredentials: true },
    );
  }

  public uploadFileEvidenceUrl(): Observable<any> {
    return this.httpClient.get<any>(
      environment.urls.apiBaseUrl + `uploads/evidences`,
      { withCredentials: true },
    );
  }

  public uploadFileEvidence(
    url: string,
    file,
    headers: UploadEvidenceHeaders,
  ): Observable<any> {
    let blobHeader: UploadEvidenceHeaders = Object.assign({}, headers);
    blobHeader.headers['x-ms-blob-type'] = 'BlockBlob';
    return this.httpClient.put<any>(url, file, blobHeader);
  }

  public validateRiskEvidence(riskId: string) {
    return this.httpClient.get<any>(
      environment.urls.apiBaseUrl + `risk_management/${riskId}`,
      {
        withCredentials: true,
      },
    );
  }

  public uploadArcherEvidence(
    appGuid: string,
    functionalCheckHash: string,
    archerId: string,
  ) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl +
        `apps/${appGuid}/functional_checks/${functionalCheckHash}/evidences`,
      { risk_management_id: archerId },
      { withCredentials: true },
    );
  }

  public uploadRiskAcceptance(
    appGuid: string,
    findingId: string,
    archerId: string,
  ) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl +
        `apps/${appGuid}/security_findings/${findingId}/targets/risk_managements`,
      { risk_management_id: archerId },
      { withCredentials: true },
    );
  }

  public uploadArcherApiScan(
    appGuid: string,
    digipiId: string,
    archerId: string,
  ) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl +
        `apps/${appGuid}/integrated_apis/${digipiId}/risk_managements/${archerId}`,
      { withCredentials: true },
    );
  }

  public unlinkArcher(appGuid: string, functionalCheckHash: string) {
    return this.httpClient.delete<any>(
      environment.urls.apiBaseUrl +
        `apps/${appGuid}/functional_checks/${functionalCheckHash}/risk_managements`,
      { withCredentials: true },
    );
  }

  public deleteRiskAcceptance(appGuid: string, securityFindingId: string) {
    return this.httpClient.delete(
      `${environment.urls.apiBaseUrl}apps/${appGuid}/security_findings/${securityFindingId}/risk_managements`,
    );
  }
}
