import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { marked } from 'marked';
import { jsonResponseApi } from 'src/app/model/json-response.model';
import { ResponseObject } from 'src/app/model/response-object.model';
import { User } from 'src/app/model/user';
import { SecurityFindingsService } from 'src/app/modules/security/services/security-findings.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ENVIRONMENT } from 'src/app/Utils/globals';
import { LoadingDialogComponent } from '../../onboarding/loading-dialog/loading-dialog.component';
import { AppFeedbackModalComponent } from '../../shared/ui/app-feedback-modal/app-feedback-modal.component';
import { AppFeedbackModalData } from '../../shared/ui/app-feedback-modal/appFeedbackModalData';
import { AcceptRiskModalComponent } from '../accept-risk-modal/accept-risk-modal.component';
import { ArcherSubmitResultModalComponent } from '../archer-submit-result-modal/archer-submit-result-modal.component';
import {
  SecurityRiskAcceptance,
  TargetBadgeStyles,
} from '../interfaces/security.interface';

@Component({
  selector: 'app-finding-detail-dialog',
  templateUrl: './finding-detail-dialog.component.html',
  styleUrls: ['./finding-detail-dialog.component.scss'],
})
export class FindingDetailDialogComponent implements OnInit {
  dataFinding: any;
  dataTitle: string;
  dataDescriptionIntro: string;
  dataFindingTargets: any;
  dataDescription: string;
  dataRecommendation: string;
  dataSecureCode: string;
  dataCompliance: any;
  dataArcher: SecurityRiskAcceptance;
  dataEnvironment: string;

  dataNotes: any[];
  user: User;
  package: string;
  technology: string;
  app_security_id: string;
  uuid: string;
  txtQuestionComment: string = '';
  blSendingPost: boolean = false;
  commentType: string = 'internal';
  acceptRiskCheck: boolean;

  constructor(
    public dialogRef: MatDialogRef<FindingDetailDialogComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public service: SecurityFindingsService,
    private readonly matDialog: MatDialog,
    private objMatDialog: MatDialog,
    public dialog: MatDialog,
    private translate: TranslateService,
  ) {
    this.updateDialogContent(data);
  }

  ngOnInit(): void {
    this.mapSecurityFindingDetail();
  }

  updateDialogContent(data: any): void {
    this.package = data.package;
    this.technology = data.technology;
    this.user = JSON.parse(data.user);
    this.app_security_id = data.app_security_id;
    this.dataFinding = data.result;
    this.uuid = data.uuid;
    this.mapSecurityFindingDetail();
  }

  mapSecurityFindingDetail(): void {
    this.dataTitle = this.dataFinding.title;
    this.dataDescriptionIntro = this.dataFinding.description_intro;
    this.dataFindingTargets = this.dataFinding.security_finding_targets;
    this.dataDescription = this.dataFinding.Description;
    this.dataRecommendation = this.dataFinding.recommendation;
    this.dataSecureCode = this.dataFinding.secure_code;
    this.dataArcher = this.dataFinding.archer;
    this.dataEnvironment = this.dataFinding.environment;

    this.dataFindingTargets.map((item) => {
      return (item.seeMore = false);
    });

    if (this.dataFinding.compliance_policy_references != null) {
      this.dataCompliance = this.dataFinding.compliance_policy_references[0];
    }
    this.dataNotes = this.dataFinding?.notes?.sort((a, b) =>
      a.created_on > b.created_on ? -1 : 1,
    );
  }

  fnSendQuestionComment(): void {
    const params = {
      text: this.txtQuestionComment,
      internal: this.commentType === 'internal',
    };
    this.blSendingPost = true;

    this.service
      .sendQuestionComment(params, this.uuid, this.app_security_id)
      .subscribe({
        next: (res: ResponseObject) => {
          this.dataNotes.unshift(res);
          this.blSendingPost = false;
          this.txtQuestionComment = null;
          this.openAppFeedbackModal();
        },
        error: (error) => {
          this.service.apiError(error.status);
          this.blSendingPost = false;
        },
      });
  }

  openAppFeedbackModal() {
    const feedbackModalData: AppFeedbackModalData = {
      type: 'info',
      title: this.translate.instant(
        'component.security-assessment.security-findings.detail.section-questions-comments.app-feedback-modal.title',
      ),
      description: this.translate.instant(
        'component.security-assessment.security-findings.detail.section-questions-comments.app-feedback-modal.description',
      ),
      buttonText: this.translate.instant(
        'component.security-assessment.security-findings.detail.section-questions-comments.app-feedback-modal.button-text',
      ),
    };

    this.matDialog.open(AppFeedbackModalComponent, {
      width: '400px',
      height: '400px',
      panelClass: 'dialogPanelClass',
      data: feedbackModalData,
    });
  }

  seeMore(item) {
    return {
      overflow: item.seeMore,
    };
  }

  checkMoreInfo(item) {
    if (item.seeMore) {
      item.seeMore = false;
      return;
    }

    item.seeMore = true;
  }

  cssClass(item) {
    return {
      up: item.seeMore,
      down: !item.seeMore,
    };
  }

  markItem(text) {
    return marked(text);
  }

  acceptRisk(targetText, targetId, canSelfClose, selfCloseMessage) {
    if (!canSelfClose) {
      this.openInsufficientRightsModal(selfCloseMessage);
      this.acceptRiskCheck = false;
    } else {
      const dialogRef = this.matDialog.open(AcceptRiskModalComponent, {
        data: {
          targetText,
        },
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.openLoadingDialog();
          this.service
            .closeOneTarget(this.uuid, this.app_security_id, targetId)
            .subscribe({
              next: (response: jsonResponseApi) => {
                var objIndex = this.dataFindingTargets.findIndex(
                  (obj) => obj.id == targetId,
                );
                this.dataFindingTargets[objIndex].self_close = false;
                this.dataFindingTargets[objIndex].current_status =
                  'CLOSED_RISK_ACCEPTED';
                this.dialog.getDialogById('loadingDialog').close();
                this.ngOnInit();
              },
              error: (error: HttpErrorResponse) => {
                this.dialog.getDialogById('loadingDialog').close();
                this.dialog
                  .getDialogById('loadingDialog')
                  .afterClosed()
                  .subscribe((result) => {
                    if (error.status === 403) {
                      this.openInsufficientRightsModal(error.error.error);
                    }
                  });
              },
            });
        }
      });
    }
  }

  showMoreContentArrow(item: any): boolean {
    return item.additional_contexts.length || item.self_close;
  }

  openLoadingDialog(): void {
    this.dialog.open(LoadingDialogComponent, {
      width: '450px',
      height: '450px',
      id: 'loadingDialog',
      panelClass: 'dialogPanelClass',
      data: {
        head: 'Closing target',
        subtext: '',
        body: 'This may take some time.',
      },
      disableClose: true,
    });
  }

  onCloseDialog() {
    this.dialogRef.close(false);
  }

  submittedArcher(success: boolean) {
    this.dialogRef.close(true);
    this.dialog.open(ArcherSubmitResultModalComponent, {
      width: '476px',
      height: '443px',
      id: 'archerSubmitModal',
      panelClass: 'dialogPanelClass',
      data: {
        status: success,
      },
      disableClose: true,
    });
  }

  getTargetBadgeStyle(item: any): TargetBadgeStyles {
    return {
      clsBadgeCmn_red: item.status === 'OPEN' || item.status === 'NEW',
      clsBadgeCmn_green:
        item.status === 'CLOSED_FIXED' ||
        item.status === 'CLOSED_ITEM_NOT_FOUND',
      clsBadgeCmn_grey:
        item.status === 'CLOSED_PARTIALLY_FIXED' ||
        item.status === 'CLOSED_RISK_ACCEPTED' ||
        item.status === 'CLOSED_COMPENSATING_CONTROL' ||
        item.status === 'OPEN_READY_TO_RESCAN' ||
        item.aggregated_status === 'CLOSED_BY_POLICY',
    };
  }

  disableCloseRiskButton(item): boolean {
    return !this.acceptRiskCheck || item.status === 'CLOSED_RISK_ACCEPTED';
  }

  isEnvironmentLive() {
    return this.dataEnvironment.toLowerCase() === ENVIRONMENT.LIVE;
  }

  private openInsufficientRightsModal(message) {
    const objDialog: DialogService = new DialogService(this.objMatDialog);

    objDialog.icon = {
      src: '../../../assets/images/icons/warning-icon.svg',
      class: 'clsIcon80x80',
    };

    objDialog.texts = {
      title: this.translate.instant('Insufficient rights'),
      main: this.translate.instant(message),
      subtext: '',
    };

    objDialog.buttons = [
      {
        btnText: 'OK',
        btnFunc: (pThisDlg: any) => {
          pThisDlg.close();
        },
      },
    ];

    objDialog.openDialog();
  }
}
