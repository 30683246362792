<div class="container-fluid container-file-upload">
  <div class="clsContainer-risk ellipsis">
    <img
      class="mr-2"
      src="../../../../assets/images/icons/risk.svg"
      width="27"
      height="24"
      alt=""
    />
    <div class="ellipsis">
      <div class="ellipsis cls-risk-title">
        <span class="risk-title">{{
          'component.security-assessment.functional-checks.risk-title'
            | translate
        }}</span>
      </div>
      <div class="">
        <span class="risk-text"
          >{{
            'component.security-assessment.functional-checks.archer-text'
              | translate
          }} </span
        ><a class="risk-text link" [href]="archerInfoUrl" target="_blank"
          >{{
            'component.security-assessment.functional-checks.learn-more'
              | translate
          }}
        </a>
      </div>
      <div class="ellipsis cls-archer-title">
        <span class="archer-title">{{
          'component.security-assessment.functional-checks.archer-title'
            | translate
        }}</span>
      </div>
      <div class="row clsContainer-info">
        <div class="col-9">
          <input
            class="field"
            [ngClass]="{ invalid: archerStatus === 'invalid' }"
            placeholder="{{
              'component.security-assessment.functional-checks.archer-placeholder'
                | translate
            }}"
            [(ngModel)]="archerId"
            (keyup)="archerInputChanged()"
          />
        </div>
        <div class="col-3">
          <button
            class="clsCardButtonSearch mr-0 ml-0"
            [disabled]="!archerId"
            (click)="searchRisk()"
          >
            {{
              'component.security-assessment.functional-checks.search'
                | translate
                | uppercase
            }}
          </button>
        </div>
      </div>
      <div *ngIf="archerStatus === 'valid'" class="row clsContainer-info">
        <div class="col-xl-10 col-lg-10 col-lg-9">
          <span class="archer-desc">{{ archer.riskManagementName }} </span>
        </div>
        <div class="col-xl-10 col-lg-10 col-lg-9">
          <span class="archer-label"
            >{{
              'component.security-assessment.functional-checks.tbl-header-status'
                | translate
            }}:
          </span>
          <span class="archer-info">{{ archer.status }} </span>
        </div>
        <div class="col-xl-10 col-lg-10 col-lg-9">
          <span class="archer-label"
            >{{
              'component.security-assessment.functional-checks.due-date'
                | translate
            }}:
          </span>
          <span class="archer-info"
            >{{ archer.dueDate | date: 'dd-MMM-yy' }}
          </span>
        </div>
        <div class="col-xl-10 col-lg-10 col-lg-9">
          <a class="archer-label link" [href]="archer.url" target="_blank"
            >{{
              'component.security-assessment.functional-checks.view-details'
                | translate
            }}
          </a>
        </div>
      </div>
      <div
        class="card-body text-center mb-3"
        *ngIf="searchCallState === globals.state_loading"
      >
        <img
          class="clsImgCenterVertical"
          src="../../../../../../assets/images/icons/reload_big_pink.gif"
        />
      </div>
      <div *ngIf="archerStatus === 'invalid'" class="row clsContainer-info">
        <div class="col-xl-10 col-lg-10 col-lg-9">
          <span class="archer-invalid">{{ archer.validationMessage }} </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="submitCallState !== globals.state_loading">
  <div class="col-12 text-center">
    <button
      class="clsCardButtonActions mr-0 ml-0 btn-submit"
      [disabled]="archerStatus !== 'valid'"
      (click)="submitRisk()"
    >
      {{ 'component.common.btnSubmit' | translate }}
    </button>
  </div>
</div>
<div class="row" *ngIf="submitCallState !== globals.state_loading">
  <div class="col-12 archer-validations-section">
    <br />
    <div class="archer-validations mt-1">
      {{
        'component.security-assessment.functional-checks.archer-evidence-rules-title'
          | translate
      }}
    </div>
    <ul>
      <li class="archer-validations">
        {{
          'component.security-assessment.functional-checks.archer-evidence-rules-first'
            | translate
        }}
      </li>
      <li class="archer-validations">
        {{
          'component.security-assessment.functional-checks.archer-evidence-rules-second'
            | translate
        }}
      </li>
      <li class="archer-validations">
        {{
          'component.security-assessment.functional-checks.archer-evidence-rules-third'
            | translate
        }}
      </li>
      <li class="archer-validations">
        {{
          'component.security-assessment.functional-checks.archer-evidence-rules-fourth'
            | translate
        }}
      </li>
    </ul>
  </div>
</div>
