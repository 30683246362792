<div [ngClass]="{ 'app-button-disabled': disabled }" class="app-button">
  <button
    [ngClass]="{ submit: submitType }"
    (click)="handleClick()"
    [disabled]="disabled"
  >
    <img
      *ngIf="icon"
      [src]="icon"
      [width]="iconWidth"
      [height]="iconHeight"
      alt=""
    />
    <p>{{ text }}</p>
  </button>
</div>
