<div *ngIf="mode === 'options'">
  <div class="">
    <div class="upload-title">
      <div *ngIf="hasPreviousEvidence">
      {{
        'component.security-assessment.functional-checks.replace-evidence-title'
          | translate
      }}
    </div>
    <div *ngIf="!hasPreviousEvidence">
      {{
        'component.security-assessment.functional-checks.upload-title'
          | translate
      }}
    </div>
  </div>
    <div class="option-text mb-2">
      {{
        'component.security-assessment.functional-checks.upload-text'
          | translate
      }}
    </div>
  </div>
  <div class="d-flex">
    <div
      class="card container-fluid clsCardContainer clsLeft"
      (click)="mode = 'addFile'"
    >
      <div class="">
        <img
          class=""
          src="../../../../assets/images/icons/upload-color.svg"
          width="45"
          height="40"
          alt=""
        />
        <div class="option-title">
          {{
            'component.security-assessment.functional-checks.upload-evidence'
              | translate
          }}
        </div>
        <div class="option-text">
          {{ this.info | translate }}
        </div>
      </div>
    </div>
    <div
      class="card container-fluid clsCardContainer clsRight"
      (click)="mode = 'addRisk'"
    >
      <div class="">
        <img
          class=""
          src="../../../../assets/images/icons/risk.svg"
          width="45"
          height="40"
          alt=""
        />
        <div class="option-title">
          {{
            'component.security-assessment.functional-checks.accept-risk'
              | translate
          }}
        </div>
        <div class="option-text">
          {{
            'component.security-assessment.functional-checks.accept-risk-text'
              | translate
          }}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Go back btn -->
<div *ngIf="mode === 'addFile' || mode === 'addRisk'">
  <div class="clsGoBack" (click)="mode = 'options'; removeFile()">
    <div class="d-flex">
      <img
        class="mr-2"
        src="../../../../assets/images/icons/icons-arrow-left.svg"
        width="15"
        height="15"
        alt=""
      />
      <span class="go-back">{{ 'common.go-back' | translate }}</span>
    </div>
  </div>
</div>
<!-- Drag Drop -->
<div
  *ngIf="!file && mode === 'addFile'"
  class="uploadfilecontainer card text-center"
  appDragDrop
  (FileDroppedEvent)="uploadFile($event)"
>
  <img
    class=""
    src="../../../../assets/images/icons/upload-grey.svg"
    width="45"
    height="40"
    alt=""
  />
  <div class="clsContent">
    <div class="clsDropText">
      {{
        'component.security-assessment.functional-checks.drag-drop' | translate
      }}
    </div>
    <div class="clsBrowseFile" for="fileInput" (click)="fileInput.click()">
      {{
        'component.security-assessment.functional-checks.browse-files'
          | translate
      }}
    </div>
    <input
      hidden
      type="file"
      #fileInput
      (change)="uploadFile($event.target.files)"
    />
  </div>
</div>
<!-- File detail -->
<div
  class="clsBorder container-fluid container-file-upload"
  *ngIf="file && mode === 'addFile'"
>
  <div class="clsContainer-info ellipsis">
    <img
      class="mr-2"
      src="../../../../assets/images/icons/report.svg"
      width="40"
      height="40"
      alt=""
    />
    <div class="ellipsis">
      <div class="clsContainer-info ellipsis mb-2">
        <span class="evidence-title ellipsis" title="{{ file.name }}">
          {{ file.name }}
        </span>
      </div>
      <div class="evidence-description small ellipsis">
        <span
          >{{
            'component.security-assessment.functional-checks.uploaded-by'
              | translate
          }}
          {{ user?.authenticated_user }}</span
        >
        -
        <span class="evidence-date">{{
          currentDate | date: 'dd-MMM-yy HH:mm'
        }}</span>
      </div>
    </div>
  </div>
  <img
    class="cls-close"
    src="../../../../assets/images/icons/icons-close-screenshots-off.png"
    width="20"
    height="20"
    alt=""
    (click)="removeFile()"
  />
</div>
<!-- Btn submit evidence -->
<div
  class="row"
  *ngIf="mode === 'addFile' && submitCallState !== globals.state_loading"
>
  <div class="col-12 text-right">
    <button
      class="clsCardButtonActions mr-0 ml-0 btn-submit"
      [disabled]="!file"
      (click)="closeUploadFile()"
    >
      {{
        'component.security-assessment.functional-checks.submit-review'
          | translate
          | uppercase
      }}
    </button>
  </div>
</div>

<!-- Add risk -->
<div *ngIf="mode === 'addRisk'">
  <app-risk-acceptance
    [appUuid]="appUuid"
    [evidenceId]="evidenceId"
    (emitRisk)="emitEvidence.emit($event)"
    (emitSearchCallState)="searchCallState = $event"
    (emitSubmitCallState)="submitCallState = $event"
  ></app-risk-acceptance>
</div>
<div
  class="card-body text-center mb-3"
  *ngIf="submitCallState === globals.state_loading"
>
  <img
    class="clsImgCenterVertical"
    src="../../../../../../assets/images/icons/reload_big_pink.gif"
  />
</div>
