import {
  Component,
  Input,
  ElementRef,
  Renderer2,
  OnDestroy,
  HostListener,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './app-tooltip.component.html',
  styleUrls: ['./app-tooltip.component.scss'],
})
export class AppTooltipComponent implements OnDestroy, OnInit {
  @Input() text = '';
  @Input() showArrow = true;
  @Input() hasPadding = false;
  tooltipElement: HTMLElement | null = null;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.createTooltip();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.removeTooltip();
  }

  ngOnInit(): void {
    this.tooltipElement = null;
  }

  createTooltip(): void {
    this.removeTooltip();

    if (!this.text) {
      return;
    }

    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipElement, 'tooltip-text');
    this.renderer.appendChild(
      this.tooltipElement,
      this.renderer.createText(this.text),
    );

    if (this.showArrow) {
      this.renderer.addClass(this.tooltipElement, 'with-arrow');
    }

    setTimeout(() => {
      const rect = this.el.nativeElement.getBoundingClientRect();

      const x = rect.left + (this.hasPadding ? 0 : -6);
      const y = rect.bottom + 10;

      this.renderer.setStyle(this.tooltipElement, 'position', 'fixed');
      this.renderer.setStyle(this.tooltipElement, 'left', `${x}px`);
      this.renderer.setStyle(this.tooltipElement, 'top', `${y}px`);
      this.renderer.setStyle(this.tooltipElement, 'z-index', '9999');

      this.renderer.appendChild(document.body, this.tooltipElement);
    }, 0);
  }

  removeTooltip(): void {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }

  ngOnDestroy(): void {
    this.removeTooltip();
  }
}
