import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  SecurityRiskAcceptance,
  SecurityTarget,
} from '../interfaces/security.interface';
import { SecurityFunctionalChecksService } from '../services/security-functional-checks.service';
import { AppConfirmationModalData } from '../../shared/ui/app-confirmation-modal/appConfirmationModalData';
import { AppConfirmationModalComponent } from '../../shared/ui/app-confirmation-modal/app-confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ReloadFunctionalCheckService } from '../services/reload-functional-check.service';
import { SecurityFindingsService } from '../services/security-findings.service';

@Component({
  selector: 'app-archer-risk-acceptance',
  templateUrl: './archer-risk-acceptance.component.html',
  styleUrls: ['./archer-risk-acceptance.component.scss'],
})
export class ArcherRiskAcceptanceComponent implements OnInit, OnChanges {
  @Input() archerInReview?: SecurityRiskAcceptance;
  @Input() uuid: string;
  @Input() securityFindingId: string;
  @Input() targets: SecurityTarget[];
  @Output() archerSubmitted = new EventEmitter();

  confirmationStep = false;
  submitting = false;
  deleting = false;
  archerId: string;

  constructor(
    private service: SecurityFunctionalChecksService,
    private translate: TranslateService,
    private readonly matDialog: MatDialog,
    private reloadService: ReloadFunctionalCheckService,
    private securityFindingsService: SecurityFindingsService,
  ) {}

  ngOnInit(): void {
    this.deleting = false;
  }

  ngOnChanges(): void {
    this.deleting = false;
  }

  toggleStep() {
    this.confirmationStep = !this.confirmationStep;
  }

  goToConfirmation(id: string) {
    this.archerId = id;
    this.toggleStep();
  }

  uploadRiskAcceptance() {
    this.submitting = true;
    this.service
      .uploadRiskAcceptance(this.uuid, this.securityFindingId, this.archerId)
      .subscribe({
        next: () => {
          this.securityFindingsService.updateTableInfo.next({
            showClosedIssues: false,
          });
          this.reloadService.triggerReload();
          this.archerSubmitted.emit(true);
          this.submitting = false;
        },
        error: () => {
          this.archerSubmitted.emit(false);
          this.submitting = false;
        },
      });
  }

  openConfirmationModal() {
    const confirmationModalData: AppConfirmationModalData = {
      type: 'warning',
      title: this.translate.instant(
        'component.app-confirmation-modal.remove-archer-functional-check.title',
      ),
      description: this.translate.instant(
        'component.app-confirmation-modal.remove-archer-functional-check.description',
      ),
      detailDescription: this.translate.instant(
        'component.app-confirmation-modal.remove-archer-functional-check.detail-description',
      ),
      buttonYesText: this.translate.instant('common.remove'),
      buttonNoText: this.translate.instant('common.cancel'),
    };
    const dialogRef = this.matDialog.open(AppConfirmationModalComponent, {
      width: '450px',
      height: '364px',
      panelClass: 'dialogPanelClass',
      data: confirmationModalData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleting = true;
        this.service
          .deleteRiskAcceptance(this.uuid, this.securityFindingId)
          .subscribe({
            next: () => {
              this.securityFindingsService.updateTableInfo.next({
                showClosedIssues: false,
              });
              this.reloadService.triggerReload();
            },
            error: (err) => {
              this.service.apiError(err.status, err.error.return_message);
              this.deleting = false;
            },
          });
      }
    });
  }
}
