import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './app-button.component.html',
  styleUrls: ['./app-button.component.scss'],
})
export class AppButtonComponent {
  @Input() text: string;
  @Input() icon: string;
  @Input() iconWidth: number;
  @Input() iconHeight: number;
  @Input() disabled: boolean;
  @Input() submitType? = false;
  @Output() clickEvent = new EventEmitter();

  handleClick() {
    this.clickEvent.emit();
  }
}
