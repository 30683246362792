import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { FooterSection } from 'src/app/model/footerSection';
import { jsonResponseApi } from 'src/app/model/json-response.model';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
})
export class MainFooterComponent implements OnInit, OnChanges {
  @Input() collapsedSideBar: boolean;
  @Input() section: any;

  sectionMessage: string;
  sectionMessageCommonPart1: string;
  sectionMessageCommonPart2: string;
  link: string;
  footerSections: FooterSection[] = [];

  constructor(
    public service: CommonService,
    private translate: TranslateService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.loadFooterSections();
    this.loadSection(this.section);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadSection(changes.section?.currentValue);
  }

  redirectContact(): void {
    this.service.getCrossFormUrl(null, this.link).subscribe({
      next: (res: jsonResponseApi) => {
        const lUrl = res.original_response;
        window.open(lUrl);
      },
      error: (error) => {
        this.service.apiError(error.status);
      },
    });
  }

  redirectRaise(): void {
    window.open(
      'https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=09112ccfdb7bbb800f71f9851d9619b6',
    );
  }

  redirectAnnouncements(): void {
    window.open('https://nestle.workplace.com/groups/mobilepublic');
  }

  private loadSection(section) {
    if (!section) {
      section = { code: this.router.url.split('/')[2].toLowerCase() };
    }

    if (!this.footerSections) {
      return;
    }

    const actualSection = this.footerSections.find(
      (footerSection: FooterSection) => {
        return footerSection.section === section.code;
      },
    );

    if (actualSection) {
      this.sectionMessage = actualSection.message;
      this.link = actualSection.link;
    }
  }

  private loadFooterSections() {
    this.footerSections = [
      {
        section: 'dashboard',
        message: this.translate.instant('component.main-footer.dashboard'),
        link: 'KB_LIGHTHOUSE_MAIN',
      },
      {
        section: 'configure',
        message: this.translate.instant('component.main-footer.configure'),
        link: 'KB_CONFIGURE_FOOTER',
      },
      {
        section: 'develop',
        message: this.translate.instant('component.main-footer.develop'),
        link: 'KB_DEVELOP_FOOTER',
      },
      {
        section: 'release',
        message: this.translate.instant('component.main-footer.release'),
        link: 'KB_RELEASE_FOOTER',
      },
      {
        section: 'testing',
        message: this.translate.instant('component.main-footer.testing'),
        link: 'KB_TESTING_FOOTER',
      },
      {
        section: 'security',
        message: this.translate.instant('component.main-footer.security'),
        link: 'KB_SECURITY_FOOTER',
      },
      {
        section: 'publish',
        message: this.translate.instant('component.main-footer.publish'),
        link: 'KB_PUBLISH_FOOTER',
      },
      {
        section: 'engage',
        message: this.translate.instant('component.main-footer.engage'),
        link: 'KB_LIGHTHOUSE_MAIN',
      },
      {
        section: 'user_management',
        message: this.translate.instant(
          'component.main-footer.user_management',
        ),
        link: 'KB_USER_MANAGEMENT_FOOTER',
      },
    ];
  }
}
