import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Action } from 'src/app/model/action.model';
import { Application } from 'src/app/model/application.model';
import { jsonResponseApi } from 'src/app/model/json-response.model';
import { PermissionInfo } from 'src/app/model/permission.model';
import { Section } from 'src/app/model/section.model';
import { User } from 'src/app/model/user';
import { CommonService } from 'src/app/services/common.service';
import accents from 'accents';
import { AppChangeService } from 'src/app/services/app-change.service';
import { AppMetaDataService } from 'src/app/modules/publish/services/app-meta-data.service';
import { Subscription } from 'rxjs';
import { AppStatusService } from 'src/app/modules/dashboard/services/app-status.service';
import { environment } from 'src/environments/environment';
import { AutomationTestingService } from 'src/app/modules/automation-testing/automation-testing.service';
import { Globals } from 'src/app/Utils/globals';
import { SecurityFindingsService } from '../../security/services/security-findings.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { LiveVersionSecurityBannerData } from 'src/app/shared/models/liveVersionSecurityBannerData';
import { MatDialog } from '@angular/material/dialog';
import { AppConfirmationModalData } from '../ui/app-confirmation-modal/appConfirmationModalData';
import { AppConfirmationModalComponent } from '../ui/app-confirmation-modal/app-confirmation-modal.component';

@Component({
  selector: 'app-left-nav-bar',
  templateUrl: './left-nav-bar.component.html',
  styleUrls: ['./left-nav-bar.component.scss'],
  providers: [Globals],
})
export class LeftNavBarComponent implements OnInit, OnDestroy {
  @Output() sideBarExpanded: EventEmitter<boolean> = new EventEmitter();
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() changeSection: EventEmitter<Section> = new EventEmitter();
  @Output() resizeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() userInfo: User = null;

  sectionList: Array<any>;
  isExpandedSidebar: boolean = false;
  selectedApp: Application;
  selectedSection: Section;
  register: Application = {
    id: 0,
    package: 'register',
    tech: 'tech',
    name: this.translate.instant('component.leftNavBar.add-new-app'),
    digipi: 0,
    permissions: new PermissionInfo(),
    requester: '',
    country: '',
    icon: '../../../assets/images/icons/add_darkblue.svg',
    live: true,
    native: true,
    source_code_type: 'native',
  };
  appsUser: Array<Application>;
  dropDownOpen: boolean = false;
  isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  removeAccents: accents;
  eventSubscription: Subscription;
  navigation: Subscription;
  sectionSub: Subscription;
  canLeave: boolean = true;
  sectionToGo: string;
  appToGo: Application;

  constructor(
    public globals: Globals,
    private service: CommonService,
    public router: Router,
    private translate: TranslateService,
    private appChangeService: AppChangeService,
    private appMetaDataService: AppMetaDataService,
    private appStatus: AppStatusService,
    private automationTestingService: AutomationTestingService,
    private readonly securityFindingsService: SecurityFindingsService,
    private readonly globalsService: GlobalsService,
    private readonly matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.subscribeToSectionChange();
    this.onResizeAction();
    this.loadDefaultApp();
    this.initDefaultSection();
    this.subscribeToNavigation();
    this.getLiveAppSecurityFindings();
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
    this.navigation.unsubscribe();
  }

  discardSelectedApp(): void {
    this.appsUser = this.userInfo.applications.slice(0);
    this.appsUser.splice(this.appsUser.indexOf(this.selectedApp), 1);
  }

  onSelectionChange(selected) {
    this.appToGo = selected;
    this.appMetaDataService.publishForm.next('app');

    if (!this.canLeave) {
      return;
    }

    this.selectApp(selected);
  }

  selectApp(selected) {
    if (selected) {
      this.selectedApp = selected;
      if (this.selectedApp.package === 'register') {
        this.router.navigate(['/onboarding']);
      } else {
        const changeData = {
          app: this.selectedApp,
          section: this.selectedSection.code,
        };
        this.selectionChange.emit(changeData);
        this.appChangeService.appChange.next(this.selectedApp);
        this.automationTestingService.testStatus.next(null);
        this.getLiveAppSecurityFindings();
      }
    }
    this.discardSelectedApp();
  }

  loadDefaultApp() {
    const appUrl = this.getAppFromUrl();
    if (appUrl) {
      this.setApplication(appUrl);
      return this.discardSelectedApp();
    }
    if (this.userInfo != null) {
      const lObjAppSelected = this.service.getDataLocalstorage();

      if (lObjAppSelected == null) {
        this.setApplication(this.userInfo.applications[0]);
      } else {
        this.setApplication(
          this.service.loadAppSelected(
            this.userInfo.applications,
            lObjAppSelected,
          ),
        );
      }
    }
    this.discardSelectedApp();
  }

  getAppFromUrl() {
    const urlUuid = this.router.url.split('/')[1].toLowerCase();
    return this.userInfo.applications.find(
      (app: Application) => app.uuid === urlUuid,
    );
  }

  setApplication(pObjAppSelected: Application): void {
    this.selectedApp = pObjAppSelected;
    this.service.setDataLocalstorage(pObjAppSelected);
  }

  initDefaultSection() {
    if (this.userInfo.is_tour) {
      this.initDashboard();
      return;
    }

    const section: string =
      this.router.url.split('/')[2] || this.globals.DASHBOARD;
    const defaultSelectedSection = new Section();
    defaultSelectedSection.code = section.toLowerCase();
    defaultSelectedSection.actions = [new Action(), new Action()];
    defaultSelectedSection.actions[0].code = 'latest_version';
    //copy value without reference
    this.selectedSection = defaultSelectedSection;
  }

  initDashboard() {
    const defaultSelectedSection = new Section();
    defaultSelectedSection.code = this.globals.DASHBOARD;
    defaultSelectedSection.actions = [new Action(), new Action()];
    defaultSelectedSection.actions[0].code = 'latest_version';
    //copy value without reference
    this.selectedSection = defaultSelectedSection;
  }

  toogleMenu() {
    this.isExpandedSidebar = !this.isExpandedSidebar;
    this.sideBarExpanded.emit(this.isExpandedSidebar);
  }

  toogleOption(sectionName: string) {
    this.sectionToGo = sectionName;
    this.appMetaDataService.publishForm.next(['']);

    if (!this.canLeave) {
      return;
    }

    this.goToSection(sectionName);
  }

  goToSection(sectionName: string) {
    this.selectedSection = this.selectedApp.permissions.sections.find(
      (section) => section.code === sectionName,
    );
    const newSection: Section = {
      code: sectionName,
      actions: [],
    };
    if (!this.selectedSection) {
      this.selectedSection = newSection;
    }
    this.changeSection.emit(this.selectedSection);

    this.router.navigate([
      `${this.selectedApp.uuid}/${this.selectedSection.code}`,
    ]);
  }

  visibleSection(sectionName: string) {
    return (
      this.selectedApp != null &&
      this.selectedApp.permissions?.sections?.find(
        (section) => section.code === sectionName,
      ) != null
    );
  }

  onResizeAction(): void {
    this.isExpandedSidebar = !(window.innerWidth >= 1170);
  }

  onClickSignOut(): void {
    const confirmationModalData: AppConfirmationModalData = {
      type: 'info',
      title: this.translate.instant(
        'component.leftNavBar.app-confirmation-modal-sign-out.title',
      ),
      description: this.translate.instant(
        'component.leftNavBar.app-confirmation-modal-sign-out.description',
      ),
      buttonYesText: this.translate.instant(
        'component.leftNavBar.app-confirmation-modal-sign-out.yes-text',
      ),
      buttonNoText: this.translate.instant(
        'component.leftNavBar.app-confirmation-modal-sign-out.no-text',
      ),
    };
    const dialogRef = this.matDialog.open(AppConfirmationModalComponent, {
      width: '480px',
      height: '400px',
      panelClass: 'dialogPanelClass',
      data: confirmationModalData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const lUrl =
          environment.urls.signOut +
          '?post_logout_redirect_uri=' +
          environment.urls.baseUrl;
        window.location.href = lUrl;
      }
    });
  }

  @ViewChild('dropdownElement', { static: false }) dropdownElement;
  dropdownChange(): void {
    const objCtrlClicked = event.target as HTMLElement;

    if (objCtrlClicked?.tagName !== 'INPUT') {
      if (this.dropDownOpen) {
        this.dropDownOpen = false;
        document.removeEventListener('click', this.onDocumentClick);
      } else {
        this.dropDownOpen = true;
        this.onDocumentClick = this.onDocumentClick.bind(this);
        document.addEventListener('click', this.onDocumentClick);
      }
    }
  }

  protected onDocumentClick(event: MouseEvent) {
    if (this.dropdownElement.nativeElement.contains(event.target)) {
      return;
    }
    this.dropdownChange();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    if (this.dropDownOpen) {
      this.dropdownChange();
    }
  }

  refreshAppList(psValue: string): void {
    const newLstFiltered: Array<Application> = [];

    this.userInfo.applications.forEach((application) => {
      if (
        accents(application.name.toLowerCase()).indexOf(
          accents(psValue.toLowerCase()),
        ) > -1
      ) {
        newLstFiltered.push(application);
      }
    });

    this.appsUser = newLstFiltered;
  }

  loadUrlMeasure(): void {
    if (this.selectedApp.live) {
      const params = {
        package: this.selectedApp.package,
        technology: this.selectedApp.tech,
      };
      const lUrlPowerBi = 'MEASURE_POWERBI_REGISTERED';
      const lUrlPowerBiTour = 'MEASURE_POWERBI_TOUR ';
      const lsKey = this.userInfo.is_tour ? lUrlPowerBiTour : lUrlPowerBi;

      this.service.getCrossFormUrl(params, lsKey).subscribe(
        (res: jsonResponseApi) => {
          const lUrl = res.original_response;
          window.open(lUrl, '_blank');
        },
        (error) => {
          this.service.apiError(error.status);
        },
      );
    } else {
      const sTitle = this.translate.instant('component.info-messages.m4.title');
      const sMsg = this.translate.instant('component.info-messages.m4.msg');
      this.service.dlgInfo(sTitle, sMsg);
    }
  }

  subscribeToSectionChange() {
    this.eventSubscription = this.appChangeService.formChange$.subscribe(
      (change: string) => {
        this.canLeave = change === 'formChanges' || change === 'canLeave';
        switch (change) {
          case 'changeApp':
            this.selectApp(this.appToGo);
            break;
          case 'canLeave':
            this.goToSection(this.sectionToGo);
            break;
        }
      },
    );
  }

  subscribeToNavigation() {
    this.navigation = this.appStatus.sectionNavigate$.subscribe(
      (redirect: any) => {
        const link = redirect.link;
        const packageName = redirect.package;
        const tech = redirect.tech;

        if (packageName) {
          const app = this.appsUser.find((app: Application) => {
            return app.package === packageName && app.tech === tech;
          });
          this.selectApp(app);
        }

        if (link) {
          this.toogleOption(link);
        }
      },
    );

    this.sectionSub = this.appChangeService.sectionChange$.subscribe(
      (newSection: string) => {
        if (newSection) {
          this.toogleOption(newSection);
        }
      },
    );
  }

  generateTestId(app) {
    const appImg = app.tech === this.globals.tech_Android ? 'android' : 'ios';
    return app.name + '-' + appImg;
  }

  getLiveAppSecurityFindings() {
    this.securityFindingsService
      .getLiveAppSecurityFindings(this.selectedApp.uuid)
      .subscribe({
        next: (res) => {
          if (!this.isLiveSecurityBannerVisible()) {
            return;
          }

          const liveVersionSecurityBannerData: LiveVersionSecurityBannerData =
            res?.securityIssuesCount > 0
              ? {
                  securityIssuesCount: res?.securityIssuesCount,
                }
              : null;
          this.globalsService.setLiveVersionSecurityBannerData(
            liveVersionSecurityBannerData,
          );
        },
      });
  }

  isLiveSecurityBannerVisible() {
    return this.service.isPortalActionVisible(
      this.selectedApp,
      this.globals.ACC_LIVE_SECURITY_BANNER,
      this.globals.DASHBOARD,
    );
  }
}
