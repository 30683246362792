import {
  Component,
  Input,
  ElementRef,
  Renderer2,
  OnDestroy,
  HostListener,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './app-tooltip.component.html',
  styleUrls: ['./app-tooltip.component.scss'],
})
export class AppTooltipComponent implements OnDestroy, OnInit {
  @Input() text = '';
  @Input() showArrow = true;
  @Input() canCopy = false;
  @Input() hasPadding = false;
  tooltipElement: HTMLElement | null = null;
  private removeTooltipTimeout: any = null;

  constructor(
    private readonly renderer: Renderer2,
    private readonly el: ElementRef,
  ) {}

  @HostListener('window:scroll')
  onScroll(): void {
    this.removeTooltip();
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent): void {
    this.createTooltip();
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent): void {
    if (this.tooltipElement?.contains(event.relatedTarget as Node)) {
      return;
    }

    if (this.canCopy) {
      this.startRemoveTooltipTimeout();
    } else {
      this.removeTooltip();
    }
  }

  @HostListener('document:mouseenter', ['$event'])
  onTooltipEnter(event: MouseEvent): void {
    if (this.tooltipElement?.contains(event.target as Node)) {
      clearTimeout(this.removeTooltipTimeout);
    }
  }

  @HostListener('document:mouseleave', ['$event'])
  onTooltipLeave(event: MouseEvent): void {
    if (
      this.tooltipElement &&
      !this.tooltipElement.contains(event.target as Node)
    ) {
      if (this.canCopy) {
        this.startRemoveTooltipTimeout();
      } else {
        this.removeTooltip();
      }
    }
  }

  ngOnInit(): void {
    this.tooltipElement = null;
  }

  createTooltip(): void {
    this.removeTooltip();

    if (!this.text) {
      return;
    }

    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipElement, 'tooltip-text');
    this.renderer.appendChild(
      this.tooltipElement,
      this.renderer.createText(this.text),
    );

    if (this.showArrow) {
      this.renderer.addClass(this.tooltipElement, 'with-arrow');
    }

    if (this.canCopy) {
      this.renderer.addClass(this.tooltipElement, 'can-copy');
    }

    setTimeout(() => {
      const rect = this.el.nativeElement.getBoundingClientRect();
      const x = rect.left + (this.hasPadding ? 0 : -6);
      const y = rect.bottom + (this.canCopy ? 5 : 10);

      this.renderer.setStyle(this.tooltipElement, 'position', 'fixed');
      this.renderer.setStyle(this.tooltipElement, 'left', `${x}px`);
      this.renderer.setStyle(this.tooltipElement, 'top', `${y}px`);
      this.renderer.setStyle(this.tooltipElement, 'z-index', '9999');
      this.renderer.appendChild(document.body, this.tooltipElement);

      this.renderer.listen(this.tooltipElement, 'mouseenter', () => {
        clearTimeout(this.removeTooltipTimeout);
      });

      this.renderer.listen(this.tooltipElement, 'mouseleave', () => {
        if (this.canCopy) {
          this.startRemoveTooltipTimeout();
        } else {
          this.removeTooltip();
        }
      });
    }, 0);
  }

  startRemoveTooltipTimeout(): void {
    if (this.removeTooltipTimeout) {
      clearTimeout(this.removeTooltipTimeout);
    }

    if (this.canCopy) {
      this.removeTooltipTimeout = setTimeout(() => {
        this.removeTooltip();
      }, 500);
    }
  }

  removeTooltip(): void {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }

  ngOnDestroy(): void {
    this.removeTooltip();
  }
}
