<div class="app-feedback-modal">
  <div class="icon">
    <img [src]="defineIconImage()" alt="" width="47.2" height="47.2"/>
  </div>
  <h1>{{ data.title }}</h1>
  <p>{{ data.description }}</p>
  <app-button
    (clickEvent)="onButtonClick()"
    [text]="data.buttonText"
  ></app-button>
</div>
