import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReloadFunctionalCheckService {
  constructor() {}
  private reloadSubject = new Subject<void>();

  reloadObservable$ = this.reloadSubject.asObservable();

  triggerReload() {
    this.reloadSubject.next();
  }
}
