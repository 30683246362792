import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../../services/common.service';
import { SecurityFindingResponse } from '../interfaces/security.interface';
import { GetLiveAppSecurityFindingsResponseDto } from '../../dashboard/models/getLiveAppSecurityFindingsResponseDto';
import { FindingsStatus } from '../models/findings-status.enum';

@Injectable({
  providedIn: 'root',
})
export class SecurityFindingsService extends CommonService {
  idSecurityFinding: string;
  updateTableInfo = new Subject<any>();
  updateTableInfo$: Observable<any> = this.updateTableInfo.asObservable();

  public getSecurityFindings(params: any) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'security/findingslist',
      params,
      { withCredentials: true },
    );
  }

  public getSecurityFindingsDetail(params: any) {
    return this.httpClient.get<SecurityFindingResponse>(
      environment.urls.apiBaseUrl +
        `apps/${params.uuid}/security_findings/${params.psId}`,
    );
  }

  public sendQuestionComment(params: any, appuid, findingId) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl +
        `apps/${appuid}/security_findings/${findingId}/comments`,
      params,
    );
  }

  public getAppDependencies(params: any) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'security/appdependencies',
      params,
      { withCredentials: true },
    );
  }

  public closeOneTarget(app, finding, target) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl +
        `apps/${app}/security_findings/${finding}/targets/${target}/close`,
      null,
    );
  }

  public getSecuritySummary(appGuid: string) {
    return this.httpClient.get<any>(
      environment.urls.apiBaseUrl + `apps/${appGuid}/security`,
      { withCredentials: true },
    );
  }

  getLiveAppSecurityFindings(appId: string) {
    return this.httpClient.get<GetLiveAppSecurityFindingsResponseDto>(
      `${environment.urls.apiBaseUrl}apps/${appId}/security/live_findings_list`,
    );
  }

  public getTabStatusImage(status: string): string {
    switch (status?.toUpperCase()) {
      case FindingsStatus.INFO:
        return 'assets/images/icons/tab_status_info_icon.svg';
      case FindingsStatus.FAILURE:
        return 'assets/images/icons/tab_status_error_icon.svg';
      case FindingsStatus.WARNING:
        return 'assets/images/icons/tab_status_warning_icon.svg';
      case FindingsStatus.SUCCESS:
        return 'assets/images/icons/tab_status_success_icon.svg';
      default:
        return '';
    }
  }
}
