import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfirmationModalData } from './appConfirmationModalData';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './app-confirmation-modal.component.html',
  styleUrls: ['./app-confirmation-modal.component.scss'],
})
export class AppConfirmationModalComponent implements OnInit {
  isWarning: boolean;
  constructor(
    public dialogRef: MatDialogRef<AppConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AppConfirmationModalData,
  ) {}

  ngOnInit(): void {
    this.isWarning = this.data.type === 'warning';
  }
  defineIconImage() {
    switch (this.data.type) {
      case 'info':
        return 'assets/images/icons/info.svg';
      case 'error':
        return 'assets/images/icons/error.svg';
      case 'warning':
        return 'assets/images/icons/warning-icon.svg';
    }
  }
  onButtonYesClick() {
    this.dialogRef.close(true);
  }
  onButtonNoClick() {
    this.dialogRef.close();
  }
}
